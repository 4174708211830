import React, { FC, ReactElement } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import RootContainer from './routes/rootContainer';
import ModalContainer from './modalContainer';
import theme from '../resources/style/theme';

const appTheme: Theme = createTheme(theme);

interface AppContainerProps {}

const AppContainer: FC<AppContainerProps> = (): ReactElement => {
    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline />
            <ModalContainer />
            <RootContainer />
        </ThemeProvider>
    );
};

export default AppContainer;
