import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, UseMutationResult } from 'react-query';
import { useHistory } from 'react-router-dom';
import { axiosInstance } from './axios';
import { ReactQueryError } from '../../apiTypes';
import { logError } from '../../../utils/errors';
import { toastError } from '../../../utils/toast';
import { paths } from '../../../navigation/routes/paths';
import { types } from '../../modules/pendings';

export interface MutationInfos {
    method?: 'post' | 'delete' | 'put';
    path: string;
    type: string; //UNIQUE
    params?: any;
}

const axiosFetcher = async (method: string = 'post', url: string, params: any): Promise<any> => {
    console.log('MUTATION - METHOD:', method, 'URL:', url, 'PARAMS: ', params);
    let response: any;
    switch (method) {
        case 'post':
        case 'put':
            response = await axiosInstance[method](url, params);
            break;
        case 'delete':
            response = await axiosInstance[method](url, { data: params });
            break;
    }
    return response.data;
};

export function useMyMutation(mutation, options): UseMutationResult {
    const history = useHistory();
    const dispatch = useDispatch();
    const { onError, onSuccess, ...otherOptions } = options;

    const mutationFn = useCallback(
        async (variables = {}) => {
            const { method, path, params, type } = mutation(variables);
            dispatch({ type: types.START_PENDING_OPERATION, payload: { type: type } });
            const res = axiosFetcher(method, path, params);
            return res;
        },
        [dispatch, mutation],
    );

    return useMutation(mutationFn, {
        onSuccess(data, variables, context) {
            const { type } = mutation(variables);
            dispatch({ type: types.STOP_PENDING_OPERATION, payload: { type: type } });

            if (onSuccess) {
                onSuccess(data, variables, context);
            }
        },
        onError: (error: ReactQueryError, variables: any, context: unknown) => {
            const { type } = mutation(variables);
            dispatch({ type: types.STOP_PENDING_OPERATION, payload: { type: type } });

            logError(error);

            const { status } = error.response;

            // Redirect 412 to home
            if (String(status) === '412') {
                history.push(paths.home);
            }

            /**
             *  if auth error redirect to id
             */
            if (onError) {
                // console.log('Using onError redefine');
                onError(error, variables, context);
            } else {
                // console.log('Using onError default');
                toastError(error?.message ?? '');
            }
        },
        ...otherOptions,
        // we want toast msg on dev & silent err on othen env, need an error boundary if activated
        // useErrorBoundary: !configs?.appConfig?.isDev,
    });
}
