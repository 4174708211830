import React, { FC, ReactElement } from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

interface SvgIconProps {
    icon: React.ElementType;
    size?: 'inherit' | 'small' | 'mediumSmall' | 'medium' | 'large';
    color?: 'default' | 'black' | 'blue' | 'grey';
    fill?: string;
    stroke?: string;
    width?: number;
    minWidth?: number;
    height?: number;
    minHeight?: number;
}

const SvgIcon: FC<SvgIconProps> = ({
    icon: Icon,
    size = 'medium',
    color = 'default',
    fill,
    stroke,
    width,
    minWidth,
    height,
    minHeight,
}): ReactElement => {
    const classes = useStyles();
    let style: any = {};
    if (fill) {
        style.fill = fill;
    }
    if (stroke) {
        style.stroke = stroke;
    }
    if (width) {
        style.width = width;
    }
    if (minWidth) {
        style.minWidth = minWidth;
    }
    if (height) {
        style.height = height;
    }
    if (minHeight) {
        style.minHeight = minHeight;
    }
    return <Icon className={clsx(classes[size], classes[color])} style={style} />;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        small: {
            width: 16,
            height: 16,
        },
        mediumSmall: {
            width: 18,
            height: 18,
        },
        medium: {
            width: 20,
            height: 20,
        },
        large: {
            width: 24,
            height: 24,
        },
        inherit: {
            width: 'inherit',
            fill: 'inherit',
            stroke: 'inherit',
        },
        default: {
            fill: 'none',
            stroke: theme.palette.colors.icon.lightGrey,
        },
        black: {
            fill: theme.palette.colors.icon.primary,
            stroke: theme.palette.colors.icon.primary,
        },
        blue: {
            fill: theme.palette.colors.background.primaryBtn,
            stroke: theme.palette.colors.background.primaryBtn,
        },
        grey: {
            fill: theme.palette.colors.icon.lightGrey,
            stroke: theme.palette.colors.icon.lightGrey,
        },
    }),
);

export default SvgIcon;
