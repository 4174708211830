import { QueryClient, QueryCache, Query, QueryClientConfig } from 'react-query';
import { useHistory } from 'react-router-dom';
import { paths } from '../../../navigation/routes/paths';
import { toastError } from '../../../utils/toast';

const config: QueryClientConfig = {
    defaultOptions: {
        queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        },
    },
    // queryCache: new QueryCache({
    //     onError: (error: any, query: Query): void => {
    //         toastError(error?.message ?? '');
    //     },
    // }),
};

const client = new QueryClient(config);

export { client };
