import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
// Components
import createModal from '../components/modals/createModalHOC';
import Confirmation from '../components/modals/confirmation';
// Modules
import {
    ModalType,
    ModalInfos,
    ActionCloseModal,
    actions as modalActions,
    selectors as modalSelectors,
} from '../core/modules/modals';
// Resources

const modalComponents = {
    [ModalType.CONFIRMATION]: createModal(Confirmation),
};

export interface ModalContainerProps {
    closeModal: ActionCloseModal;
    isOpen: boolean;
    modal: ModalInfos;
    modalsLength: number;
}

const ModalContainer: FC<ModalContainerProps> = (props): ReactElement => {
    const { isOpen, closeModal, modalsLength, modal } = props;

    if (modalsLength && modalsLength > 0) {
        const modalType: ModalType = modal?.modalType;
        const id: string = modal?.id;
        const modalProps: any = modal?.modalProps;

        const handleCloseModal = (): void => {
            closeModal(id);
        };

        // modal component created by corresponding ModalType
        const ModalComponent = modalComponents[modalType];

        return <ModalComponent {...modalProps} {...props} onRequestClose={handleCloseModal} isOpen={isOpen} />;
    }
    return null;
};

const mapStateToProps = (state) => ({
    modalsLength: modalSelectors.modalsLength(state),
    modal: modalSelectors.modal(state),
    isOpen: modalSelectors.isOpen(state),
});

const mapDispatchToProps = {
    closeModal: modalActions.closeModal,
    openModal: modalActions.openModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
