import axios from 'axios';
import Cookies from 'js-cookie';
import configs from '../../../config';

// Request ID Api
export const axiosInstance = axios.create({
    baseURL: configs.envConfig.currentEnv.idURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': configs.envConfig.currentEnv.authURL,
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        const token = Cookies.get('token');
        if (token) {
            config.headers['Token'] = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// Request Private Api
export const axiosInstancePrivApi = axios.create({
    baseURL: configs.envConfig.currentEnv.apiURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    },
});

axiosInstancePrivApi.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        const token = Cookies.get('token');
        if (token) {
            config.headers.Token = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);
