import React, { FC, ReactElement } from 'react';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import _ from 'lodash';

interface TextButtonProps extends ButtonProps {
    title: string;
    onClick: (e) => void;
    colorStyle?: 'blue' | 'grey';
    iconElement?: JSX.Element;
    className?: string;
    isLoading?: boolean;
}

const TextButton: FC<TextButtonProps> = (props): ReactElement => {
    const { title, onClick, iconElement, className, colorStyle = 'blue', isLoading = false, ...rest } = props;
    const classes = useStyles();

    const action = _.debounce(onClick, 300);

    return (
        <Button
            onClick={isLoading ? null : action}
            startIcon={iconElement}
            disableRipple
            className={clsx(classes.button, colorStyle === 'blue' ? classes.blue : classes.grey, className)}
            {...rest}
        >
            {isLoading ? <CircularProgress color="inherit" size={20} /> : title}
        </Button>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        blue: {
            color: theme.palette.colors.text.primaryButton,
            backgroundColor: theme.palette.colors.background.primaryBtn,
            '&:hover': {
                backgroundColor: theme.palette.colors.background.hoverPrimaryBtn,
            },
            '&.Mui-disabled': {
                opacity: 0.3,
                color: theme.palette.colors.text.primaryButton,
            },
        },
        grey: {
            color: theme.palette.colors.text.secondaryButton,
            backgroundColor: theme.palette.colors.background.secondaryBtn,
            '&:hover': {
                backgroundColor: theme.palette.colors.background.hoverSecondaryBtn,
            },
            '&.Mui-disabled': {
                opacity: 0.3,
                color: theme.palette.colors.text.secondaryButton,
            },
        },
        button: {
            fontSize: 18,
            fontWeight: 500,
            fontFamily: 'Madras',
            letterSpacing: '0.2px',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: 20,
            paddingRight: 20,
            borderRadius: 10,
            justifySelf: 'center',
            /* alignSelf: 'center', */
        },
    }),
);

export default TextButton;
