export default {
    profile: {
        profile: 'Profile',
        editProfile: 'Edit my profile',
        changePwd: 'Change my password',
        logout: 'Logout',
        editInfos: 'Edit my information',
        editPassword: 'Edit my password',
        firstname: 'First name',
        lastname: 'Last name',
        email: 'Email',
        phone: 'Phone',
        emailPro: 'Professional Email',
        company: 'Company',
        group: 'Group',
        confirmButton: 'Confirm',
        cancelButton: 'Cancel',
        password: 'Password',
        newPassword: 'New password',
        confirmPassword: 'Confirm your password',
    },
    errors: {
        canNotBeEmpty: 'Cannot be empty',
        invalidFormat: 'Invalid format',
        captcha: 'An error occurred for validation. Please try again.',
    },
    common: {
        signup: 'Sign up',
        signingUp: 'Signing up',
        connection: 'Connect',
        connect: 'Connect',
        disconnect: 'Disconnect',
        confirm: 'Confirm',
        add: 'Add',
        back: 'Back',
        next: 'Next',
        continue: 'Continue',
        edit: 'Modify',
        delete: 'Delete',
        reset: 'Reset',
        search: 'Search',
        noResult: 'No result',
        perso: 'Personal',
        pro: 'Pro',
        dot: '•',
        rideBetween: 'Rides between',
        displayRidesBetween: 'Display the rides between the dates',
        starDateLabel: 'Starting date',
        endDateLabel: 'Ending date',
        resetFilter: 'Reset filters',
        clear: 'Clear',
        cancel: 'Cancel',
        cancelled: 'Cancelled',
        save: 'Save',
        skip: 'Skip',
        na: 'N/A',
        more: 'See more',
        blabla: 'Lorem ipsum',
        forgot: 'Forgot your password?',
    },
    time: {
        format: "MM/dd/yyyy 'at' HH:mm",
        lettreFormat: 'ddd DD MMM [at] HH:mm',
        isoFormat: 'yyyy-MM-DD[T]HH:mm',
        h: 'h',
        m: 'm',
        s: 's',
        hours: 'Hours',
        minutes: 'Minutes',
        seconds: 'Seconds',
    },
    concur: {
        accessDenied: 'Incorrect email address or password',
        signinWithLeCab: 'Sign in with LeCab to link your SAP Concur account',
        loginSuccessful: 'Successful connection',
        concurLinkSuccessful: 'Your SAP Concur account has been linked successfully',
        concurLinkError: 'An error occurred during the link. Please try again later',
        termsAndConditions: 'Terms of Sale',
        privacyPolicy: 'Privacy Policy',
        contactUs: 'Contact Us',
    },
    login: {
        title: {
            signup: 'Create your profile',
            portal: 'Welcome to the portal',
            otp: 'Receive your code',
            askOtp: 'Validate your phone number',
            changePhone: 'Change phone number',
            missingPhone: 'Add a phone number',
            password: 'Password',
            setPassword: 'Secure your account',
            activate: 'Check your inbox',
            welcomeBack: 'Glad to see you again',
        },
        placeholder: {
            phone: 'Phone number',
            email: 'Email',
            emailPhone: 'Email or phone number',
            otp: 'Code or password',
            otpOnly: 'Code',
            password: 'Password',
            createPassword: 'Create your password',
            confirmPassword: 'Confirm your password',
        },
        link: {
            resendOtp: 'Resend code',
            skip: 'Skip',
        },
        helper: {
            needSignup: 'New to LeCab?',
            needSignin: 'Already have an account?',
            needEmail: 'This phone is not validated, please first connect using your email.',
            didNotReceive: 'Did not receive anything?',
            passwordWithMail: 'Enter the password associated with the email <b>{{mail}}</b>',
            passwordWithPhone: 'Enter the password associated with the phone <b>{{mail}}</b>',
            otpCode: 'Enter the 4-digit code sent to <b>** ** ** {{phone}}</b> or <b>your password</b>',
            otpOnly: 'Enter the 4-digit code sent to <b>** ** ** {{phone}}</b>',
            askOtp: 'Validate your phone number <b>** ** ** {{phone}}</b> to be able to call your driver and receive ride tracking by SMS.',
            changePhone: 'Complete your profile with a validated phone number.',
            missingPhone: 'Complete your profile with a phone number',
            activate: 'To activate your account, please click on the link we just sent to the address <b>{{mail}}</b>.',
            forgot: 'Enter your email, and we will send you a link to reset your password.',
        },
        action: {
            resetPassword: 'Reset password',
            resendMail: 'Resend email',
        },
        password: {
            minimumCharacter: '{{min}} characters.',
            rule: 'Uppercase, lowercase letters, numbers, and symbol ({{special}}).',
        },
        error: {
            password: 'Wrong password',
            passwordDifferent: 'Passwords do not match',
            passwordRules: 'Passwords do not meet the criteria',
            otpCode: 'Incorrect verification code',
            default: 'An error occurred, please try again later',
            domainError: 'Incorrect domain name.',
            validationError: 'Your email could not be activated, please try again later',
            joinGroupError: 'Error when adding to the group, please try again later',
            passwordExpiredError: 'Your password reset link is expired. Try Again',
            inviteError: 'Your invitation link is no longer valid, please contact your company administrator',
            phoneHomeError:
                'You must enter a mobile phone number in order to receive the tracking SMS of your ride or to call your driver.',
            phoneInvalidError: 'Invalide number, please correct your number.',
        },
        info: {
            forgot: 'If an account with this email <b>{{email}}</b> exists, an email will be sent with additional information to reset your password.',
        },
        official: {
            cgu: "By checking this box, you acknowledge that you have read and accepted our <a href='https://lecab.fr/conditions-generales-de-vente/'>Terms and Conditions</a>. *",
            marketing: 'I agree to receive promotional emails from LeCab',
        },
    },
};
