import * as Sentry from '@sentry/react';

// Module Name
export const name = 'data';

// ActionTypes
export const types = {
    SET_EMAIL: name + '/SET_EMAIL',
    SET_PHONE: name + '/SET_PHONE',
};

export interface DataState {
    email: string;
    phone: string;
}

// InitialState
export const initialState: DataState = {
    email: '',
    phone: '',
};

// Reducer
export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case types.SET_EMAIL:
            Sentry.setUser({ email: action.payload.email });
            return {
                ...state,
                email: action.payload.email,
            };
        case types.SET_PHONE:
            Sentry.setUser({ phone: action.payload.phone });
            return {
                ...state,
                phone: action.payload.phone,
            };
        default:
            return state;
    }
};

// ActionCreators
export const actions = {
    setEmail: (email: string) => ({ type: types.SET_EMAIL, payload: { email } }),
    setPhone: (phone: string) => ({ type: types.SET_PHONE, payload: { phone } }),
};

// Selectors
const localState = (state: any): DataState => state[name] || initialState;
export const selectors = {
    email: (state) => localState(state).email,
    phone: (state) => localState(state).phone,
};
