import React, { FC, ReactElement } from 'react';
// components
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Space, { Type } from '../layout/space';
import Button from '@material-ui/core/Button';
// ressources
import stringsRes from '../../resources/strings';
import TextButton from '../buttons/textButton';

interface PropsFromModal {
    onRequestClose?: () => void;
}

interface ConfirmationProps extends PropsFromModal {
    title: string;
    description?: string;
    labelCancel?: string;
    hasCancel?: boolean;
    labelConfirm?: string;
    onConfirmAction?: () => void;
    onCancelAction?: () => void;
}

const Confirmation: FC<ConfirmationProps> = (props): ReactElement => {
    const {
        onRequestClose,
        title,
        description,
        labelCancel,
        hasCancel = true,
        labelConfirm,
        onConfirmAction,
        onCancelAction,
    } = props;
    const classes = useStyle();

    const onClickConfirm = (): void => {
        if (onConfirmAction) {
            onConfirmAction();
        }
        onRequestClose();
    };

    const onClickCancel = (): void => {
        if (onCancelAction) {
            onCancelAction();
        }
        onRequestClose();
    };

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Typography variant="h1">{title}</Typography>
                <Space type={Type.vertical} value={1} />
                <Typography variant="body1">{description}</Typography>
            </div>
            <div className={classes.buttonContainer}>
                {hasCancel && (
                    <TextButton
                        title={labelCancel ?? stringsRes.common.cancel}
                        className={classes.button}
                        colorStyle="grey"
                        onClick={onClickCancel}
                    />
                )}
                <TextButton title={labelConfirm ?? stringsRes.common.confirm} onClick={onClickConfirm} />
            </div>
        </div>
    );
};

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3),
        },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: theme.spacing(3),
        },
        buttonContainer: {
            display: 'flex',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
            flexDirection: 'row',
            justifyContent: 'center',
        },
        button: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: '100px',
            maxWidth: '160px',
        },
    }),
);

export default Confirmation;
