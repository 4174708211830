// Module Name
import { v1 as uuidv1 } from 'uuid';
import _ from 'lodash';

export const name = 'modals';

/////// MODALTYPE ///////
export enum ModalType {
    CONFIRMATION = 'CONFIRMATION',
}

// ActionTypes
export const types = {
    OPEN_MODAL: name + '/OPEN_MODAL',
    CLOSE_MODAL: name + '/CLOSE_MODAL',
    DISPLAY_MODAL: name + '/DISPLAY_MODAL',
};

export interface ModalInfos {
    modalType: ModalType;
    modalProps: any;
    id: any;
}

// InitialState
export const initialState = {
    isOpen: false,
    modals: Array<ModalInfos>(),
};

export type ModalState = typeof initialState;

function isModalExists(state: ModalState, actionProps: any): boolean {
    return !!state.modals.find((modal) => _.isEqual(modal.modalProps, actionProps));
}

// Reducer
export default (state = initialState, action: any = {}) => {
    switch (action.type) {
        case types.OPEN_MODAL:
            const foundModal = isModalExists(state, action.payload.props);
            let stateModals = {};
            if (!foundModal) {
                stateModals = {
                    modals: [
                        ...state.modals,
                        {
                            id: action.payload.id,
                            modalType: action.payload.modalType,
                            modalProps: action.payload.props,
                        },
                    ],
                };
            }
            return {
                ...state,
                ...stateModals,
            };
        case types.DISPLAY_MODAL:
            return {
                ...state,
                isOpen: true,
            };
        case types.CLOSE_MODAL:
            const modals = state.modals.filter((modal) => modal.id !== action.payload.id);
            return {
                ...state,
                modals,
                isOpen: false,
            };
        default:
            return state;
    }
};

export type ActionOpenModal = (modalType: ModalType, props: any) => any;
export type ActionCloseModal = (id: string) => any;

// ActionCreators
export const actions = {
    openModal: (modalType: ModalType, props: any, id?: string) => ({
        type: types.OPEN_MODAL,
        payload: { modalType, props, id: id ? id : uuidv1() },
    }),
    closeModal: (id) => ({ type: types.CLOSE_MODAL, payload: { id: id } }),
    displayModal: () => ({ type: types.DISPLAY_MODAL }),
};

// Selectors
const localState = (state: any): ModalState => state[name] || initialState;
export const selectors = {
    isOpen: (state) => localState(state).isOpen,
    modalsLength: (state) => localState(state).modals.length,
    modals: (state) => localState(state).modals,
    modal: (state) => localState(state).modals[0],
};
