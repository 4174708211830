import React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import clsx from 'clsx';

type ComponentType<T> = React.ComponentClass<T> | React.FunctionComponent<T>;

interface ModalHocProps extends WithStyles {
    isOpen: boolean;
    onRequestClose: () => void;
    modalStyle?: any;
}

function createModal<ComponentProps extends {}>(Component: ComponentType<ComponentProps>) {
    type MergedProps = ComponentProps & ModalHocProps;

    class ModalHoc extends React.Component<MergedProps> {
        static displayName = `ModalHoc(${Component.displayName || Component.name})`;

        render() {
            const { isOpen, onRequestClose, classes } = this.props;

            return (
                <Dialog
                    open={isOpen}
                    onClose={onRequestClose}
                    maxWidth={'sm'}
                    closeAfterTransition
                    fullWidth
                    classes={{ paper: clsx(classes.dialog) }}
                >
                    <DialogContent style={dialogStyles}>
                        <Component {...(this.props as any)} />
                    </DialogContent>
                </Dialog>
            );
        }
    }

    return withStyles(styles)(ModalHoc);
}

export default createModal;

const dialogStyles: React.CSSProperties = { margin: 0, padding: 0 };
const styles = (theme) =>
    createStyles({
        modal: {},
        dialog: {
            borderRadius: 5,
            position: 'absolute' as any,
            top: '20vh',
            backgroundColor: 'white',
        },
    });
