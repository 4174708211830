import React, { FC, ReactElement } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { history } from '../../core/modules';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import queryString from 'query-string';
// Components
import ToastContainer from './toastProvider';
import ResponsiveCenterContainer from '../../components/layout/responsiveCenterContainer';
import lazyWrapper from '../../components/lazyWrapper';
import LogoView from '../../components/logoView';
// Ressources
import { paths } from './paths';
import configs from '../../config';
import asyncStorageApi, { KEY_WV_STATUS_BAR_HEIGHT } from '../../localStorageAPI';

//= ====================================//
//= ============== Pages ===============//
//= ====================================//

const TestPage = React.lazy(() => lazyWrapper(() => import('../routes/test')) as any);
const HomePage = React.lazy(() => lazyWrapper(() => import('../routes/home')) as any);
const ConcurPage = React.lazy(() => lazyWrapper(() => import('../routes/concur')) as any);

const CaptchaPage = React.lazy(() => lazyWrapper(() => import('../routes/captcha')) as any);

// const DetectPage = React.lazy(() => lazyWrapper(() => import('../routes/detect')) as any);

const OtpPage = React.lazy(() => lazyWrapper(() => import('../routes/otp')) as any);
const AskPhonePage = React.lazy(() => lazyWrapper(() => import('../routes/askPhone')) as any);
const VerifyPhonePage = React.lazy(() => lazyWrapper(() => import('../routes/verifyPhone')) as any);
const PasswordPage = React.lazy(() => lazyWrapper(() => import('../routes/password')) as any);
const SetPasswordPage = React.lazy(() => lazyWrapper(() => import('../routes/setPassword')) as any);
const ActivatePage = React.lazy(() => lazyWrapper(() => import('../routes/activate')) as any);
const ActivateEmailPage = React.lazy(() => lazyWrapper(() => import('../routes/activateEmail')) as any);
const ForgotPage = React.lazy(() => lazyWrapper(() => import('../routes/forgot')) as any);

const SignUpPage = React.lazy(() => lazyWrapper(() => import('../routes/signup')) as any);
const PortalPage = React.lazy(() => lazyWrapper(() => import('../routes/portal')) as any);
const ChangePhonePage = React.lazy(() => lazyWrapper(() => import('../routes/changePhone')) as any);
const JoinGroupPage = React.lazy(() => lazyWrapper(() => import('../routes/joinGroup')) as any);

//= ============================================//
//= ============== RootContainer ===============//
//= ============================================//

interface RootContainerProps {}

const RootContainer: FC<RootContainerProps> = (): ReactElement => {
    const classes = useStyles({});
    const parsed = queryString.parse(window.location.search);

    React.useEffect(() => {
        const { referrer } = document;
        if (
            referrer &&
            referrer.includes(configs.envConfig.currentEnv.domain) &&
            referrer.match(/:\/\/(.[^/]+)/)[1].endsWith(configs.envConfig.currentEnv.domain)
        ) {
            console.log('saving referrer');
            asyncStorageApi.persistRedirect(referrer);
        }
    }, []);

    //= ===============================================//
    //= ============== PARSED & Actions ===============//
    //= ===============================================//
    if (parsed.statusBarHeight) {
        asyncStorageApi.persistDataNoLink(KEY_WV_STATUS_BAR_HEIGHT, parsed.statusBarHeight);
    }

    return (
        <div className={classes.rootContainer}>
            <React.Suspense fallback={<div>...Loading</div>}>
                <Router history={history}>
                    <ResponsiveCenterContainer>
                        <Paper className={classes.rootPaper}>
                            <ToastContainer />
                            <LogoView />
                            <Switch>
                                <Route path={paths.home} component={HomePage} />
                                <Route exact path={paths.concur} component={ConcurPage} />
                                <Route exact path={paths.test} component={TestPage} />
                                <Route exact path={paths.captcha} component={CaptchaPage} />
                                {/* <Route exact path={paths.detect} component={DetectPage} /> */}
                                <Route exact path={paths.otp} component={OtpPage} />
                                <Route exact path={paths.validatePhone} component={VerifyPhonePage} />
                                <Route exact path={paths.askPhone} component={AskPhonePage} />
                                <Route exact path={paths.password} component={PasswordPage} />
                                <Route exact path={paths.setPassword} component={SetPasswordPage} />
                                <Route exact path={paths.activate} component={ActivatePage} />
                                <Route exact path={paths.emailActivate} component={ActivateEmailPage} />
                                <Route exact path={paths.forgot} component={ForgotPage} />
                                <Route exact path={paths.forgotPassword} component={SetPasswordPage} />
                                <Route exact path={paths.signup} component={SignUpPage} />
                                <Route exact path={paths.portal} component={PortalPage} />
                                <Route exact path={paths.changePhone} component={ChangePhonePage} />
                                <Route exact path={paths.joinGroup} component={JoinGroupPage} />

                                <Redirect from={paths.root} to={paths.home} />
                            </Switch>
                        </Paper>
                    </ResponsiveCenterContainer>
                </Router>
            </React.Suspense>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh',
            backgroundColor: 'white',
        },
        rootPaper: {
            display: 'flex',
            margin: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.colors.background.appBar,
            flexDirection: 'column',
            flex: 1,
        },
    }),
);

export default RootContainer;
