import React, { FC, ReactElement } from 'react';
import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { paths } from '../../navigation/routes/paths';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

interface ResponsivePanelProps {
    children: React.ReactNode;
}

const LARGE_SCREEN_PATHS = [paths.signup, paths.portal.split(':')[0], paths.setPassword];

const ResponsivePanel: FC<ResponsivePanelProps> = ({ children }): ReactElement => {
    const classes = useStyles();
    const { pathname }: { pathname: string } = useLocation();

    const isLargeScreen = LARGE_SCREEN_PATHS.some((path) => pathname.startsWith(path));

    return (
        <div className={classes.container}>
            <div className={classes.flexGrow} />
            <div className={clsx(classes.childrenContainer, isLargeScreen ? classes.largeScreen : '')}>{children}</div>
            <div className={classes.flexGrow} />
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexGrow: {
            display: 'flex',
            flexGrow: 1,
        },
        container: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
        },
        childrenContainer: {
            borderRadius: 10,
            backgroundColor: theme.palette.colors.background.appBar,
            [theme.breakpoints.up('sm')]: {
                width: '100%',
                maxWidth: 380,
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                padding: theme.spacing(4),
            },
            // [theme.breakpoints.up('md')]: {
            //     boxShadow: '0px 0px 26px rgba(0, 0, 0, 0.25)',
            // },
        },
        largeScreen: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: 540,
            },
        },
    }),
);

export default ResponsivePanel;
